import {action} from '@relativity/js-util'
import {
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO,
  DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS,
  STATUS_MESSAGE_TYPE_ERROR,
  STATUS_MESSAGE_TYPE_INFO, STATUS_MESSAGE_TYPE_SUCCESS
} from "redux/constants";

export const SEND_ATTENDANCE = 'SEND_ATTENDANCE'
export const KIOSK_SETUP_WIZARD_UPDATE_PROPERTY = 'KIOSK_SETUP_WIZARD_UPDATE_PROPERTY'
export const ID_CODE_PASTED_INPUT = 'ID_CODE_PASTED_INPUT'
export const KEYBOARD_INPUT = 'KEYBOARD_INPUT'
export const KIOSK_SET_PRINTABLE_SLIP_CONTENT = 'KIOSK_SET_PRINTABLE_SLIP_CONTENT'
export const KIOSK_BOOT_L1_NEW_SET_WIZARD_DATA = 'KIOSK_BOOT_L1_NEW_SET_WIZARD_DATA'
export const KIOSK_ACTIVITY_SET_READY_TO_SEND = 'KIOSK_ACTIVITY_SET_READY_TO_SEND'
export const KIOSK_UI_SET_READY_TO_SEND = 'KIOSK_UI_SET_READY_TO_SEND'
export const REMOVE_ID_CODE_CHAR = 'REMOVE_ID_CODE_CHAR'
export const ADD_ID_CODE_CHAR = 'ADD_ID_CODE_CHAR'
export const KIOSK_LOGIN_UI_SHOW = 'KIOSK_LOGIN_UI_SHOW'
export const KIOSK_LOGIN_UI_HIDE = 'KIOSK_LOGIN_UI_HIDE'
export const KIOSK_LOGOUT_SEND = 'KIOSK_LOGOUT_SEND'
export const KIOSK_LOGIN_SEND = 'KIOSK_LOGIN_SEND'
export const KIOSK_LOGIN_SET_USER = 'KIOSK_LOGIN_SET_USER'


export const KIOSK_SNACK_MESSAGE_SHOW = 'KIOSK_SNACK_MESSAGE_SHOW'
export const KIOSK_SNACK_MESSAGE_HIDE = 'KIOSK_SNACK_MESSAGE_HIDE'

export const kioskWizardUpdateProperty = (facility) => {
  return action(KIOSK_SETUP_WIZARD_UPDATE_PROPERTY, facility)
}

export const sendAttendance = (type) => {
  return action(SEND_ATTENDANCE, type)
}

export const idCodeKeyboardInput = (key) => {
  return action(KEYBOARD_INPUT, key);
}

export const idCodePastedInput = (numbers) => {
  return action(ID_CODE_PASTED_INPUT, numbers);
}

export const ID_CODE_SCANNED_INPUT = 'KIOSK_ID_CODE_SCANNED_INPUT'
export const idCodeScannedInput = (numbers) => {
  return action(ID_CODE_SCANNED_INPUT, numbers);
}

export const SET_ATTENDANCE_TYPE = 'KIOSK_SET_ATTENDANCE_TYPE'
export const setAttendanceType = (payload) => {
  return action(SET_ATTENDANCE_TYPE, payload)
}

export const setPrintableSlipContent = (payload) => {
  return action(KIOSK_SET_PRINTABLE_SLIP_CONTENT, payload)
}

export const bootSetWizardData = (val) => {
  return action(KIOSK_BOOT_L1_NEW_SET_WIZARD_DATA, val)
}

export const uiSetReadyToSend = (val=false) => {
  return action(KIOSK_UI_SET_READY_TO_SEND, val)
}

export const setActivityReadyToSend = (val) => {
  return action(KIOSK_ACTIVITY_SET_READY_TO_SEND, val)
}

export const removeIdCodeChar = (chars) => {
  return action(REMOVE_ID_CODE_CHAR, chars)
}

export const addIdCodeChar = (char) => {
  return action(ADD_ID_CODE_CHAR, char)
}

export const loginShowUI = (payload) => {
  return action(KIOSK_LOGIN_UI_SHOW, payload)
}

export const loginUIHide = (payload) => {
  return action(KIOSK_LOGIN_UI_HIDE, payload)
}

export const loginSetUser = (payload) => {
  return action(KIOSK_LOGIN_SET_USER, payload)
}

export const loginSend = (payload) => {
  return action(KIOSK_LOGIN_SEND, payload)
}

export const logoutSend = () => {
  return action(KIOSK_LOGOUT_SEND)
}

export const KIOSK_FORGOT_PASSWORD_SEND = 'KIOSK_FORGOT_PASSWORD_SEND'
export const forgotPasswordSend = (payload) => {
  return action(KIOSK_FORGOT_PASSWORD_SEND, payload)
}

export const KIOSK_RESET_PASSWORD = 'KIOSK_RESET_PASSWORD'
export const resetPassword = (payload) => {
  return action(KIOSK_RESET_PASSWORD, payload)
}

export const KIOSK_CHECK_PASSWORD_TOKEN = 'KIOSK_CHECK_PASSWORD_TOKEN'
export const checkPasswordToken = (token) => {
  return action(KIOSK_CHECK_PASSWORD_TOKEN, token)
}

export const snackMessageShow = (userMessage, messageType, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS) => {
  return action(KIOSK_SNACK_MESSAGE_SHOW,  {userMessage, messageType, autoHideMillis})
}

export const snackMessageHide = (payload={}) => {
  return action(KIOSK_SNACK_MESSAGE_HIDE, payload)
}

export const snackMessageInfo = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_INFO) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_INFO, autoHideMillis)
}

export const snackMessageError = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_ERROR) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_ERROR, autoHideMillis)
}

export const snackMessageSuccess = (userMessage, autoHideMillis = DEFAULT_SNACK_MESSAGE_AUTO_HIDE_MILLIS_SUCCESS) => {
  return snackMessageShow(userMessage, STATUS_MESSAGE_TYPE_SUCCESS, autoHideMillis)
}

export const VIEW_ATTENDANCE_POLICY = 'KIOSK_VIEW_ATTENDANCE_POLICY'
export const viewAttendancePolicy = () => {
  return action(VIEW_ATTENDANCE_POLICY)
}

export const HIDE_STATIC_CONTENT_DIALOG = 'KIOSK_HIDE_STATIC_CONTENT_DIALOG'
export const hideStaticContentDialog = () => {
  return action(HIDE_STATIC_CONTENT_DIALOG)
}

export const SHOW_STATIC_CONTENT_DIALOG = 'KIOSK_SHOW_STATIC_CONTENT_DIALOG'
export const showStaticContentDialog = (payload) => {
  return action(SHOW_STATIC_CONTENT_DIALOG, payload)
}

export const CAMERA_START = 'KIOSK_CAMERA_POLL_START'
export const cameraStart = () => {
  return action(CAMERA_START)
}

export const CAMERA_STOP = 'KIOSK_CAMERA_POLL_STOP'
export const cameraStop = () => {
  return action(CAMERA_STOP)
}


export const CAMERA_UPDATE_SETTING = 'KIOSK_CAMERA_UPDATE_SETTING'
export const cameraUpdateSetting =(payload) => {
  return action(CAMERA_UPDATE_SETTING, payload)
}

export const CAMERA_SET_DEVICE_LIST = 'KIOSK_CAMERA_SET_DEVICE_LIST'
export const cameraSetDeviceList =(payload) => {
  return action(CAMERA_SET_DEVICE_LIST, payload)
}

export const CAMERA_TOGGLE_SHOW_VIDEO = 'KIOSK_CAMERA_TOGGLE_SHOW_VIDEO'
export const cameraToggleShowVideo =() => {
  return action(CAMERA_TOGGLE_SHOW_VIDEO)
}

export const CAMERA_TOGGLE_TORCH = 'KIOSK_CAMERA_TOGGLE_TORCH'
export const cameraToggleTorch =() => {
  return action(CAMERA_TOGGLE_TORCH)
}

export const CAMERA_TORCH_ON = 'KIOSK_CAMERA_TORCH_ON'
export const cameraTorchOn =(payload) => {
  return action(CAMERA_TORCH_ON, payload)
}

export const CHECKIN_FLOW_POLL_STOP = 'KIOSK_CHECKIN_FLOW_POLL_STOP'
export const stopCheckinheckinFlowPoll = () => {
  return action(CHECKIN_FLOW_POLL_STOP)
}

export const CHECKIN_FLOW_POLL_START = 'KIOSK_CHECKIN_FLOW_POLL_START'
export const startCheckinFlowPoll = () => {
  return action(CHECKIN_FLOW_POLL_START)
}

export const START_GPS_POLL = 'KIOSK_START_GPS_POLL'
export const startGpsPoll = () => {
  return action(START_GPS_POLL)
}

export const STOP_GPS_POLL = 'KIOSK_STOP_GPS_POLL'
export const stopGpsPoll = () => {
  return action(STOP_GPS_POLL)
}

export const KIOSK_IS_READY = 'KIOSK_IS_READY'
export const isReady = () => {
  return action(KIOSK_IS_READY)
}

export const UPDATE_SETTING = 'KIOSK_UPDATE_SETTING'
export const updateSetting = (propertyName, value) => {
  const payload  = typeof value !== "undefined"? {propertyName, value} : propertyName
  return action(UPDATE_SETTING, payload)
}

export const TOGGLE_SETTING = 'KIOSK_TOGGLE_SETTING'
export const toggleSetting = (data) => {
  return action(TOGGLE_SETTING, data)
}

export const LOCK_KIOSK = 'KIOSK_LOCK'
export const lockKiosk = (data) => {
  return action(LOCK_KIOSK, data)
}

export const UNLOCK_KIOSK = 'KIOSK_UNLOCK'
export const unlockKiosk = (data) => {
  return action(UNLOCK_KIOSK, data)
}

export const BOOT_KIOSK = 'KIOSK_BOOT'
export const bootKiosk = (kioskType=false) => {
  return action(BOOT_KIOSK, kioskType)
}

export const DELETE_KIOSK = 'KIOSK_DELETE'
export const deleteKiosk = () => {
  return action(DELETE_KIOSK)
}

export const BOOT_L1_EXISTING_SET_CONFIGURATION = 'KIOSK_BOOT_L1_EXISTING_SET_CONFIGURATION'
export const bootSetConfiguration = (val) => {
  return action(BOOT_L1_EXISTING_SET_CONFIGURATION, val)
}

export const KIOSK_FACILITIES_CUSTOM_SETUP_EDIT = 'KIOSK_FACILITIES_CUSTOM_SETUP_EDIT';
export const kioskFacilitiesCustomSetupEdit = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_EDIT, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_APPLY = 'KIOSK_FACILITIES_CUSTOM_SETUP_APPLY';
export const kioskFacilitiesCustomSetupApply = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_APPLY, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE = 'KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE';
export const kioskFacilitiesCustomSetupEditDone = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_FROM_ACTIVE_KIOSK = 'KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_FROM_ACTIVE_KIOSK';
export const kioskFacilitiesCustomSetupEditFromActiveKiosk = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_FROM_ACTIVE_KIOSK, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY_FROM_ACTIVE_KIOSK = 'KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY_FROM_ACTIVE_KIOSK';
export const kioskFacilitiesCustomSetupUpdatePropertyFromActiveKiosk = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_UPDATE_PROPERTY_FROM_ACTIVE_KIOSK, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE_FROM_ACTIVE_KIOSK = 'KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE_FROM_ACTIVE_KIOSK';
export const kioskFacilitiesCustomSetupEditDoneFromActiveKiosk = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_EDIT_DONE_FROM_ACTIVE_KIOSK, payload);
};

export const KIOSK_FACILITIES_CUSTOM_SETUP_SAVE_FROM_ACTIVE_KIOSK = 'KIOSK_FACILITIES_CUSTOM_SETUP_SAVE_FROM_ACTIVE_KIOSK';
export const kioskFacilitiesCustomSetupSaveFromActiveKiosk = (payload) => {
  return action(KIOSK_FACILITIES_CUSTOM_SETUP_SAVE_FROM_ACTIVE_KIOSK, payload);
};

export const KIOSK_UPDATE_AT_RECORD_TEMPLATES = 'KIOSK_UPDATE_AT_RECORD_TEMPLATES';
export const updateAtRecordTemplates = (payload) => {
  return action(KIOSK_UPDATE_AT_RECORD_TEMPLATES, payload);
}
import React from "react"
import connect from "react-redux/es/connect/connect"
import {Typography, withStyles} from "@material-ui/core"
import CurrentFacility from "apps/admin/components/CurrentFacility"
import PollIcon from '@material-ui/icons/Poll'
import {URL_REPORTS} from 'redux/constants'
import Grid from "@material-ui/core/Grid";
import FancySelect from "common/components/FancySelect";
import {reportPageEditUpdateProperty, reportPageFetchReportById, reportTypesFetch} from "redux/admin/Actions";
import CustomConfiguration from "apps/admin/components/CustomConfiguration";
import DatePickerFirstAndLastGrid from "apps/admin/components/DatePickerFirstAndLastGrid";
import ReportControls from "apps/admin/components/ReportControls";

const styles = {}

class Reports extends React.Component {

  componentDidMount() {
    this.props.reportTypesFetch()
  }

  render() {
    const {
      firstDateYMD=false,
      lastDateYMD=false,
      reportPageEditUpdateProperty,
      reportPageFetchReportById,
      reportTypeId,
      reportTypes,
      reportTypesById,
      jsbCustomConfiguration,
      report=false } = this.props

    const reportType = reportTypeId? reportTypesById(reportTypeId) : false
    return (
      <div>
        <CurrentFacility/>
        <Typography variant="subtitle1">Select the dates for which you would like to run a report.</Typography>
        {reportType.hasDateIntervalFilter 
          ? (<DatePickerFirstAndLastGrid firstDateYMD={firstDateYMD} lastDateYMD={lastDateYMD} onChange={reportPageEditUpdateProperty} xs={6} sm={3} md={3}/>) 
          : null}
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <FancySelect
              dispatchUpdate={(prop)=>{
                reportPageEditUpdateProperty({propertyName:'reportTypeId', val:prop.val})
              }}
              label='Report'
              propertyName={'id'}
              currentVal={reportTypeId}
              items={reportTypes}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={6} md={4}>
            <CustomConfiguration editUpdateProperty={reportPageEditUpdateProperty} jsbCustomConfiguration={jsbCustomConfiguration} />
          </Grid>
        </Grid>
        <ReportControls firstDateYMD={firstDateYMD} lastDateYMD={lastDateYMD} fetchReportAction={reportPageFetchReportById} report={report} reportType={reportType} />
      </div>
    )
  }
}

const mapStateToProps = state => state.admin.reportPage

const mapDispatchToProps = {
  reportPageEditUpdateProperty,
  reportPageFetchReportById,
  reportTypesFetch
}

export const ReportsPage = connect(
  mapStateToProps,
  mapDispatchToProps,
)(withStyles(styles, { withTheme: true })(Reports))

export const reportsPage = {
  icon: PollIcon,
  component: ReportsPage,
  name: 'Reports',
  displayName: 'Reports',
  to: URL_REPORTS
}

export default ReportsPage
